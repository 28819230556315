import React from "react";
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from "react-router-dom";
import AppRouter from "./Router/AppRouter";
import "./App.css";
import { theme } from "../styles/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={AppRouter()} />
    </ThemeProvider>
  );
}

export default App;
