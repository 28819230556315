import React from "react";
import DaitaGuardIconSVG from "./daitaGuardIcon1.svg";
import DaitaGuardIconSVG2 from "./daitaGuardIcon2.svg"
import DaitaCompareSVG from "./daitaCOMPARE.svg"
export default function DaitaGuardIcon() {

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={DaitaCompareSVG}
        style={{ height: 24}}
        alt="daitaguard logo"
      />
    </div>
  );
}
