import React, { useState } from "react";
import { Box, Divider, Switch, Typography } from "@mui/material";
import ReactDiffViewer from "react-diff-viewer";
import pdfToText from "react-pdftotext";
import FileUploadDropzone from "../FileUpload/FileUploadDropzone";
import FileRow from "../FileUpload/FileRow";
import { getFileSize } from "../FileUpload/FileUploadDropzone";
import BannerDocument from "./BannerDocument";

const DocumentCompare = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [splitViewEnabled, setSplitViewEnabled] = useState(true);

  const handleFile1Change = async (files) => {
    const selectedFile = files[0];
    setFile1(selectedFile);

    if (selectedFile) {
      let fileContent = "";
      if (selectedFile.type === "application/pdf") {
        fileContent = await pdfToText(selectedFile);
      } else {
        fileContent = await readTextFileContent(selectedFile);
      }
      setText1(fileContent);
    }
  };

  const handleFile2Change = async (files) => {
    const selectedFile = files[0];
    setFile2(selectedFile);

    if (selectedFile) {
      let fileContent = "";
      if (selectedFile.type === "application/pdf") {
        fileContent = await pdfToText(selectedFile);
      } else {
        fileContent = await readTextFileContent(selectedFile);
      }
      setText2(fileContent);
    }
  };

  const readTextFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        resolve(content);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  return (
    <Box>
      <Box sx={{ marginBottom: 1 }}>
        <BannerDocument />
      </Box>
      <Box sx={{ display: "flex", gap: 6 }}>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "8px",
            }}
          >
            <Typography variant="h6">Original File</Typography>
          </Box>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            {!file1 && (
              <FileUploadDropzone
                uploadMultiple={false}
                maxFileSize={1048576}
                acceptedFileTypes={[
                  ".txt",
                  ".csv",
                  ".sql",
                  ".json",
                  ".md",
                  ".xml",
                  ".yaml",
                  ".yml",
                  ".py",
                  ".js",
                  ".jsx",
                  ".ts",
                  ".tsx",
                  ".java",
                  ".cpp",
                  ".html",
                  ".pdf",
                ]}
                dropzoneAccept={{
                  "text/plain": [
                    ".txt",
                    ".md",
                    ".py",
                    ".js",
                    ".jsx",
                    ".ts",
                    ".tsx",
                    ".java",
                    ".cpp",
                    ".html",
                  ],
                  "text/csv": [".csv"],
                  "application/sql": [".sql"],
                  "application/json": [".json"],
                  "application/xml": [".xml"],
                  "application/x-yaml": [".yaml", ".yml"],
                  "application/pdf": [".pdf"],
                  // add other MIME types as needed
                }}
                onFileUpload={handleFile1Change}
              />
            )}
            {file1 && (
              <div style={{ padding: "16px 0px" }}>
                <FileRow
                  name={file1.name}
                  size={getFileSize(file1.size)}
                  handleDeleteFile={() => {
                    setFile1(null);
                    setText1("");
                  }}
                />
              </div>
            )}
          </div>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "8px",
            }}
          >
            <Typography variant="h6">Changed File</Typography>
          </Box>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            {!file2 && (
              <FileUploadDropzone
                uploadMultiple={false}
                maxFileSize={1048576}
                acceptedFileTypes={[
                  ".txt",
                  ".csv",
                  ".sql",
                  ".json",
                  ".md",
                  ".xml",
                  ".yaml",
                  ".yml",
                  ".py",
                  ".js",
                  ".jsx",
                  ".ts",
                  ".tsx",
                  ".java",
                  ".cpp",
                  ".html",
                  ".pdf",
                ]}
                dropzoneAccept={{
                  "text/plain": [
                    ".txt",
                    ".md",
                    ".py",
                    ".js",
                    ".jsx",
                    ".ts",
                    ".tsx",
                    ".java",
                    ".cpp",
                    ".html",
                  ],
                  "text/csv": [".csv"],
                  "application/sql": [".sql"],
                  "application/json": [".json"],
                  "application/xml": [".xml"],
                  "application/x-yaml": [".yaml", ".yml"],
                  "application/pdf": [".pdf"],
                  // add other MIME types as needed
                }}
                onFileUpload={handleFile2Change}
              />
            )}
            {file2 && (
              <div style={{ padding: "16px 0px" }}>
                <FileRow
                  name={file2.name}
                  size={getFileSize(file2.size)}
                  handleDeleteFile={() => {
                    setFile2(null);
                    setText2("");
                  }}
                />
              </div>
            )}
          </div>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      {(text1 || text2) && (
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <Switch
            checked={splitViewEnabled}
            onChange={() => setSplitViewEnabled(!splitViewEnabled)}
          />
          <Typography>Split View</Typography>
        </div>
      )}
      <div
        style={{
          width: "100%",
          maxHeight: "calc(100vh - 120px)",
          overflow: "auto",
        }}
      >
        <ReactDiffViewer
          oldValue={text1}
          newValue={text2}
          splitView={splitViewEnabled}
        />
      </div>
    </Box>
  );
};

export default DocumentCompare;
