import React from "react";
import {
  UploadFile as UploadFileIcon,
} from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Typography, Box, IconButton } from "@mui/material";

export default function FileRow({ name, size, handleDeleteFile }) {
  return (
    <div
      style={{
        border: "0.25px solid #D3D3D3",
        borderRadius: "4px",
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{display: "flex", gap: "16px", alignItems: "center"}}>
        <UploadFileIcon />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="body1">{name}</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography variant="caption" color="text.secondary">
              {size}
            </Typography>
          </Box>
        </Box>
      </div>

      <IconButton onClick={handleDeleteFile} aria-label="delete-file-button">
        <DeleteIcon />
      </IconButton>
    </div>
  );
}
