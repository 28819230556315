import React, { useContext } from "react";
import { FC, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { Topbar, Sidebar } from "../mainLayout";

const MainLayout: FC = () => {
  const [isSbExpanded, setIsSbExpanded] = useState(false);

  const handleSbToggle = () => {
    setIsSbExpanded((prevState) => !prevState);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Sidebar isSbExpanded={isSbExpanded} onSbToggle={handleSbToggle} />
      <Topbar isSbExpanded={isSbExpanded} />
      <Box
        component={"main"}
        sx={{
          width: "100%",
          marginTop: "56px",
          padding: "16px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
