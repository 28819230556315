import React from "react";
import { Avatar, Typography, Box, Link } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "./FileUploadButton.css";

interface FileUploadButtonProps {
  buttonState: "default" | "error" | "drag-drop";
  uploadButtonCaption: string;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  buttonState,
  uploadButtonCaption,
}) => {
  return (
    <Box className={`${buttonState} upload-button`}>
      <Avatar sx={{ bgcolor: "transparent" }}>
        <UploadFileIcon className="upload-file-icon" color="primary" />
      </Avatar>
      <Typography sx={{ whiteSpace: "nowrap" }}>
        <Link sx={{ cursor: "pointer" }}>Click to upload</Link> or drag and drop
      </Typography>
      <Typography variant="caption" className="upload-caption">
        {uploadButtonCaption}
      </Typography>
    </Box>
  );
};

export default FileUploadButton;
