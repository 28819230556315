import React, { useState } from "react";
import { Box, TextField, Divider, Typography, Switch } from "@mui/material";
import ReactDiffViewer from "react-diff-viewer";
import BannerText from "./BannerText";

const TextCompare = () => {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [splitViewEnabled, setSplitViewEnabled] = useState(true);

  return (
    <Box>
      <Box sx={{ marginBottom: 1 }}>
        <BannerText />
      </Box>
      <Box sx={{ display: "flex", gap: 6 }}>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              paddingBottom: "8px",
            }}
          >
            <Typography variant="h6">Original Text</Typography>
          </Box>
          <div style={{ padding: "16px 0px" }}>
            <TextField
              multiline
              rows={10}
              placeholder="Paste text here"
              value={text1}
              onChange={(e) => setText1(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </div>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              paddingBottom: "8px",
            }}
          >
            <Typography variant="h6">Changed Text</Typography>
          </Box>
          <div style={{ padding: "16px 0px" }}>
            <TextField
              multiline
              rows={10}
              placeholder="Paste text here"
              value={text2}
              onChange={(e) => setText2(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </div>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      {(text1 || text2) && (
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <Switch
            checked={splitViewEnabled}
            onChange={() => setSplitViewEnabled(!splitViewEnabled)}
          />
          <Typography>Split View</Typography>
        </div>
      )}
      <div
        style={{
          width: "100%",
          maxHeight: "calc(100vh - 120px)",
          overflow: "auto",
        }}
      >
        <ReactDiffViewer
          oldValue={text1}
          newValue={text2}
          splitView={splitViewEnabled}
        />
      </div>
    </Box>
  );
};

export default TextCompare;
