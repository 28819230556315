export const mainSbTabs = [
  {
    label: "Text",
    iconName: "TextIcon",
    path: "/text",
  },
  {
    label: "Documents",
    iconName: "DocumentIcon",
    path: "/",
  },
  // {
  //   label: "Sheets",
  //   iconName: "SpreadsheetIcon",
  //   path: "/sheets",
  // },
]
