import React from "react";
import { FC } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
  CSSObject,
} from "@mui/material";
import TabIcon from "./TabIcon";
import { SbCollapsedIcon, SbExpandedIcon } from "../icons";
import { mainSbTabs } from "../../constants";
import { Link, useLocation } from "react-router-dom";

interface ISidebar {
  isSbExpanded: boolean;
  onSbToggle: () => void;
}

// expandedMixin
const expandedMixin = (theme: Theme): CSSObject => ({
  overflowX: "hidden",
  borderRight: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.grey[100],
  width: theme.spacing(31),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

// collapsedMixin
const collapsedMixin = (theme: Theme): CSSObject => ({
  overflowX: "hidden",
  borderRight: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.grey[100],
  width: theme.spacing(9),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

// DrawerHeader
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height: theme.spacing(7),
  padding: theme.spacing(3, 1.75, 1, 2),
}));

// DrawerBody
const DrawerBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  height: "100%",
}));

// CustomDrawer
const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "isSbExpanded",
})<{ isSbExpanded: boolean }>(({ theme, isSbExpanded }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",

  ...(isSbExpanded && {
    ...expandedMixin(theme),
    "& .MuiDrawer-paper": expandedMixin(theme),
  }),

  ...(!isSbExpanded && {
    ...collapsedMixin(theme),
    "& .MuiDrawer-paper": collapsedMixin(theme),
  }),
}));

// Logo
const Logo = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: theme.spacing(7),
}));

// Sidebar component
const Sidebar: FC<ISidebar> = ({ isSbExpanded, onSbToggle }) => {
  const { pathname } = useLocation();

  return (
    <CustomDrawer anchor="left" variant="permanent" isSbExpanded={isSbExpanded}>
      <DrawerHeader>
        <IconButton onClick={onSbToggle}>
          {isSbExpanded ? <SbExpandedIcon /> : <SbCollapsedIcon />}
        </IconButton>
      </DrawerHeader>

      <DrawerBody>
        <List>
          {mainSbTabs.map(({ label, iconName, path }) => (
            <ListItem key={label} disablePadding>
              <ListItemButton
                component={Link}
                to={path}
                selected={path === pathname}
                sx={{ gap: 3, px: 3 }}
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <TabIcon iconName={iconName} />
                </ListItemIcon>

                <ListItemText sx={{ opacity: isSbExpanded ? 1 : 0 }}>
                  {label}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Logo>
          <a
            href="https://daitasoft.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              src={isSbExpanded ? "/images/logo.svg" : "/images/logo-icon.svg"}
              alt="Logo"
              width={isSbExpanded ? 125 : 24}
              height={isSbExpanded ? 24 : 17}
            />
          </a>
        </Logo>
      </DrawerBody>
    </CustomDrawer>
  );
};

export default Sidebar;
