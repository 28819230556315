import React, { useState } from "react";
import { useDropzone, FileRejection, DropzoneOptions } from "react-dropzone";
import FileUploadButton from "./FileUploadButton";

export function getFileSize(fileSize: number) {
  if (fileSize < 0) {
    throw new Error("File size must be positive.");
  }
  const fSExt = new Array("Bytes", "KB", "MB", "GB");
  let i = 0;

  while (fileSize > 900) {
    fileSize /= 1024;
    i++;
  }

  return `${Math.round(fileSize * 100) / 100 + " " + fSExt[i]}`;
}

interface FileUploadDropzoneProps {
    uploadMultiple: boolean;
    maxFileSize: number;
    acceptedFileTypes: string[];
    dropzoneAccept: DropzoneOptions["accept"];
    onFileUpload: (acceptedFiles: File[]) => void;
  }
  
  const FileUploadDropzone: React.FC<FileUploadDropzoneProps> = ({
    uploadMultiple,
    maxFileSize,
    acceptedFileTypes,
    dropzoneAccept,
    onFileUpload,
  }) => {
    const defaultUploadButtonCaption = `${acceptedFileTypes.join(
      ", "
    )} (max ${getFileSize(maxFileSize)})`;
  
    const [buttonState, setButtonState] = useState<"default" | "error" | "drag-drop">("default");
    const [uploadButtonCaption, setUploadButtonCaption] = useState<string>(defaultUploadButtonCaption);
  
    function fileValidator(file: File): { code: string; message: string } | null {
      if (file.size > maxFileSize) {
        const message = `File must be less than ${getFileSize(maxFileSize)}.`;
        setUploadButtonCaption(message);
        return {
          code: "file-too-large",
          message: message,
        };
      } else {
        const fileName = file.name;
  
        if (fileName) {
          const fileExtension = fileName.slice(fileName.lastIndexOf("."));
  
          if (!acceptedFileTypes.includes(fileExtension.toLowerCase())) {
            const message = `File must be of type ${acceptedFileTypes.join(", ")}.`;
            setUploadButtonCaption(message);
            return {
              code: "file-wrong-type",
              message: message,
            };
          }
        }
      }
  
      return null;
    }
  
    const { getRootProps, getInputProps } = useDropzone({
      multiple: uploadMultiple,
      accept: dropzoneAccept,
      validator: fileValidator,
      onDropAccepted: (acceptedFiles: File[]) => {
        onFileUpload(acceptedFiles);
        setButtonState("default");
      },
      onDropRejected: (rejectedFiles: FileRejection[]) => {
        setButtonState("error");
        if (!uploadMultiple && rejectedFiles.length > 1) {
          setUploadButtonCaption("Only one file may be uploaded.");
        }
      },
      onDragEnter: () => {
        setButtonState("drag-drop");
        setUploadButtonCaption(defaultUploadButtonCaption);
      },
      onDragLeave: () => setButtonState("default"),
    });
  
    return (
      <div>
        <div {...getRootProps({ className: "dropzone", "aria-label": "dropzone" })}>
          <input {...getInputProps()} />
          <FileUploadButton
            buttonState={buttonState}
            uploadButtonCaption={uploadButtonCaption}
          />
        </div>
      </div>
    );
  };
  
  export default FileUploadDropzone;