import { createTheme } from "@mui/material"

export const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif"
  },
  palette: {
    primary: {
      main: "#2b72fd"
    },
    success: {
      main: "#99c24d"
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#00000099"
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#0000003b"
            }
          }
        }
      }
    }
  }
})