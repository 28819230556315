import { createBrowserRouter } from "react-router-dom";
import { NotFound, paths } from ".";
import { MainLayout } from "../mainLayout";
import React from "react";
import TextCompare from "../FileCompare/TextCompare"
import DocumentCompare from "../FileCompare/DocumentCompare"

const {
  homePath,
} = paths;

const getRoutes = () => {
  const routes = [
    {
      element: <MainLayout />,
      children: [
        {
          path: homePath,
          element: <DocumentCompare />,
        },
        {
          path: "text",
          element: <TextCompare />,
        },
        // {
        //   path: "documents",
        //   element: <DocumentCompare />,
        // },
        // {
        //   path: "sheets",
        //   element: <FileCompareMUI />,
        // },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];
  
  return routes;
};

const AppRouter = () => {
  
  const routes = getRoutes();
  const router = createBrowserRouter(routes);

  return router;
};

export default AppRouter;

