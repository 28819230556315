import { Typography } from "@mui/material";
import React from "react";
import { Article as DocumentIcon } from "@mui/icons-material";

export default function BannerDocument() {
  return (
    <div
      style={{
        display: "flex",
        padding: "10px",
        borderRadius: "4px",
        background: "rgba(33, 150, 243, 0.15)",
        borderRadius: 4,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 0,
        display: "inline-flex",
        flexDirection: "column",
        paddingRight: "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <DocumentIcon fontSize="small" />
        <Typography variant="subtitle1" fontWeight="bold">
          Compare Files
        </Typography>
      </div>
      <Typography sx={{ paddingLeft: "30px" }}>
        Find the difference between .txt, .csv, .pdf, .sql, .json, .md, .xml,
        .yaml, .yml, .py, .js, .jsx, .ts, .tsx, .java, .cpp, .html files.
      </Typography>
      <Typography sx={{ paddingLeft: "30px" }}>
        Select the files you would like to compare and see the differences
        below.
      </Typography>
    </div>
  );
}
