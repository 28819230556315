import React, { FC } from "react"
import { Link as RRDLink } from "react-router-dom"
import { Box, Button, Link, Typography, styled } from "@mui/material"
import { paths } from "../Router"
import { emailAddress } from "../../constants"

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100vh",
  padding: theme.spacing(0, 2)
}))

const StyledContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const StyledFooter = styled("footer")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1.25),
  padding: theme.spacing(2.5, 2)
}))

const NotFound: FC = () => {
  const { homePath } = paths

  return (
    <StyledContainer>
      <StyledContent>
        <Typography
          variant="h1"
          align="center"
          color="primary"
          fontSize={120}
          fontWeight={600}
          mt={10}
        >
          404
        </Typography>

        <Typography
          variant="h4"
          align="center"
          color="textPrimary"
          textTransform="uppercase"
          fontSize={24}
          fontWeight={600}
          mt={6.25}
        >
          Page not found
        </Typography>

        <Typography variant="body2" align="center" maxWidth={416} mt={2}>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </Typography>

        <Button
          component={RRDLink}
          to={homePath}
          variant="contained"
          color="primary"
          size="small"
          sx={{ mt: 4 }}
        >
          Back to homepage
        </Button>
      </StyledContent>

      <StyledFooter>
        <Typography variant="caption" align="center" color="textSecondary">
          For help, please contact{" "}
          <Link
            href={`mailto:${emailAddress}`}
            target="_blank"
            rel="noreferrer noopener"
            color="inherit"
            underline="hover"
          >
            {emailAddress}
          </Link>
          .
        </Typography>

        <Box
          component="img"
          src="/images/logo.svg"
          alt="Logo"
          width={144}
          height={30}
        />
      </StyledFooter>
    </StyledContainer>
  )
}

export default NotFound