import React from "react";
import { FC } from "react";
import {
  Article as DocumentIcon,
  BorderAll as SpreadsheetIcon,
} from "@mui/icons-material";

import TextFieldsIcon from "@mui/icons-material/TextFields";

interface ITabIcon {
  iconName: string;
}

const TabIcon: FC<ITabIcon> = ({ iconName }) => {
  switch (iconName) {
    case "TextIcon":
      return <TextFieldsIcon />;
    case "DocumentIcon":
      return <DocumentIcon />;
    case "SpreadsheetIcon":
      return <SpreadsheetIcon />;
    default:
      return null;
  }
};

export default TabIcon;
