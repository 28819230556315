import { Typography } from "@mui/material";
import React from "react";
import TextFieldsIcon from "@mui/icons-material/TextFields";

export default function BannerText() {
  return (
    <div
      style={{
        display: "flex",
        padding: "10px",
        borderRadius: "4px",
        background: "rgba(33, 150, 243, 0.15)",
        borderRadius: 4,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 0,
        display: "inline-flex",
        flexDirection: "column",
        paddingRight: "30px"
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <TextFieldsIcon fontSize="small" />
        <Typography variant="subtitle1" fontWeight="bold">
          Compare Text
        </Typography>
      </div>
      <Typography sx={{ paddingLeft: "30px" }}>
        Find the difference between two texts.
      </Typography>
      <Typography sx={{ paddingLeft: "30px" }}>
        Type or paste the text you would like to compare to see the differences
        below.
      </Typography>
    </div>
  );
}
