import React from "react"
import { SVGProps } from "react"

const SbCollapsedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={1.5}
    width={24}
    height={24}
    {...props}
  >
    <path
      d="M19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.5 21V3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.5 10L7.25 12L5.5 14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SbCollapsedIcon
