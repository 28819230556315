import React from "react";
import { FC } from "react";
import { AppBar, Toolbar, styled, useTheme } from "@mui/material";
import DaitaGuardIcon from "../icons/DaitaGuardIcon";

interface ITopbar {
  isSbExpanded: boolean;
}

const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isSbExpanded",
})<Pick<ITopbar, "isSbExpanded">>(({ theme, isSbExpanded }) => ({
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: `${theme.palette.grey[100]}`,
  width: `calc(100% - ${theme.spacing(9)})`,
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(isSbExpanded && {
    width: `calc(100% - ${theme.spacing(31)})`,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Topbar: FC<ITopbar> = ({ isSbExpanded }) => {
  const theme = useTheme();

  return (
    <CustomAppBar position="fixed" isSbExpanded={isSbExpanded}>
      <Toolbar
        variant="dense"
        sx={{
          minHeight: theme.spacing(7),
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <DaitaGuardIcon />
      </Toolbar>
    </CustomAppBar>
  );
};

export default Topbar;
